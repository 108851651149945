<template>
  <div class="page-wrapper">
    <el-form label-width="100px" inline :model="dataModule">
      <el-form-item>
        <el-input placeholder="请输入内容" v-model="dataModule.inputVal" clearable>
          <el-select clearable v-model="dataModule.paramType" slot="prepend" placeholder="请选择">
            <el-option
              :key="item[0]"
              :value="item[0]"
              :label="item[1]"
              v-for="item in Object.entries(paramOptions)"
            ></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="handleQuery" />
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleEvent('model')">创建模型</el-button>
        <!--  <el-button type="primary" @click="handleEvent('user')">创建用户组</el-button> -->
        <el-button type="primary" @click="handleEvent('category')">创建分类</el-button>
      </el-form-item>
    </el-form>

    <el-tabs v-model="dataModule.type" @tab-click="handleEvent('tab')">
      <el-tab-pane :label="item.label" :name="item.key" v-for="item in tabList" :key="item.key" />
    </el-tabs>

    <FlowForm ref="eleForm" @update="handleQuery" />
    <!-- <UserForm ref="userForm" @update="handleQuery" /> -->
    <ClassifyForm ref="categoryRef" @update="handleQuery" />
    <component ref="compRef" :is="currentComp" :params="params" />
  </div>
</template>

<script>
import Flow from './flow/index'
// import User from './user/index'
import Classify from './classify/index'
import FlowForm from './flow/components/eleForm'
// import UserForm from './user/components/eleForm'
import ClassifyForm from './classify/components/eleForm'

export default {
  components: {
    Flow,
    // User,
    Classify,
    FlowForm,
    // UserForm,
    ClassifyForm,
  },
  data() {
    return {
      params: {},
      paramOptions: {},
      currentComp: null,
      dataModule: {
        type: 'model',
        inputVal: '',
        paramType: 'name',
      },
      tabList: [
        {
          key: 'model',
          label: '流程模型',
          component: 'Flow',
          keywords: {
            name: '流程模型名称',
            id: '流程模型ID',
          },
        },
        // {
        //   key: 'user',
        //   label: '用户组',
        //   component: 'User',
        //   keywords: {
        //     groupName: '用户组名',
        //     id: '用户组ID',
        //   },
        // },
        {
          key: 'category',
          label: '分类',
          component: 'Classify',
          keywords: {
            name: '分类名称',
            code: '分类编码',
          },
        },
      ],
    }
  },
  mounted() {
    this.getParamConfig()
  },
  methods: {
    getParamConfig() {
      const { keywords, component } =
        this.tabList.find((item) => item.key === this.dataModule.type) ?? {}

      if (keywords) {
        this.currentComp = component
        this.paramOptions = keywords
        this.dataModule.paramType = Object.keys(keywords)[0]
      }

      this.handleQuery()
    },
    handleQuery() {
      this.params = {}
      const { paramType, inputVal } = this.dataModule
      if (paramType) this.params[paramType] = inputVal

      this.$nextTick(() => {
        this.$refs.compRef.getDataList()
      })
    },
    handleEvent(type) {
      switch (type) {
        case 'tab':
          this.getParamConfig()
          break
        case 'model':
        case 'user':
        case 'category': {
          const opers = {
            model: () => {
              this.$refs['eleForm'].initialData()
            },
            user: () => {
              this.$refs['userForm'].initialData()
            },
            category: () => {
              this.$refs['categoryRef'].initialData()
            },
          }

          if (opers[type]) opers[type]()
          setTimeout(() => {
            this.dataModule.type = type
            this.getParamConfig()
          }, 200)
          break
        }
        default:
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-wrapper {
  ::v-deep .el-form {
    .el-input-group__append {
      background: $theme-color;
    }
    .el-icon-search {
      font-size: 14px;
      color: #fff;
    }
    .el-input-group__prepend {
      background: #fff;

      .el-input {
        width: 120px;
      }
    }
  }
}
</style>
