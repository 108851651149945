<template>
  <el-dialog :title="isEdit ? '编辑分类' : '创建分类'" :visible.sync="isShow" :width="'500px'">
    <el-form label-width="100px" :rules="rules" ref="ruleForm" :model="formData">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="分类编码" prop="code">
        <el-input v-model="formData.code"></el-input>
      </el-form-item>
      <el-form-item label="备注:" prop="categoryDesc">
        <el-input type="textarea" v-model="formData.categoryDesc" :autosize="{ minRows: 3 }" />
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="isShow = false">取 消</el-button>
      <el-button type="primary" @click="handleEvent('submit')" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import http from '@/utils/axios'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      formData: {},
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  methods: {
    handleEvent(type) {
      switch (type) {
        case 'submit':
          this.$refs['ruleForm'].validate((valid) => {
            if (!valid) return false
            this.submit()
          })
          break
        default:
      }
    },
    initialData() {
      this.isShow = true
      this.$refs['ruleForm']?.resetFields()
    },
    async submit() {
      const url = `cmp-workflow/modelCategory/createBackEnd`

      try {
        this.loading = true
        await http.post(url, this.formData)
        this.$msg.success('操作成功')

        setTimeout(() => {
          this.isShow = false
          this.$emit('update', true)
        }, 1000)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
