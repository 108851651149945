<template>
  <div>
    <common-layout>
      <template #default>
        <el-table
          border
          stripe
          ref="table"
          :data="tableData"
          v-loading="loading"
          height="calc(100vh - 240px)"
        >
          <el-table-column type="index" label="#" align="center" width="80" />
          <el-table-column prop="id" label="流程模型ID/名称" align="center" width="220">
            <template slot-scope="scope">
              {{ scope.row.id }}
              <br />
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="流程模型类型" align="center" width="120" />
          <el-table-column
            prop="modelKey"
            label="流程模型Key"
            align="center"
            width="260"
            show-overflow-tooltip
          />
          <el-table-column prop="createTime" label="创建时间" align="center" width="200" />
          <el-table-column prop="relateStatus" label="关联状态" align="center" width="140" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEvent('editRelative', scope.row)">编辑关联</el-button>
              <el-button type="text" @click="handleEvent('edit', scope.row)">编辑发布</el-button>
              <el-button type="text" @click="handleEvent('delete', scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :total="total"
          :page-size="page.size"
          :current-page="page.current"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </template>
    </common-layout>

    <EleForm ref="formRef" :formData="formData" @update="handleEvent('query')" />
    <el-dialog title="编辑流程" :visible.sync="isShow" fullscreen>
      <div style="width:calc(100vw); height:calc(100vh)" :key="updateKey">
        <iframe
          id="flowIframe"
          name="rightFrame"
          width="100%"
          height="100%"
          marginheight="0"
          marginwidth="0"
          frameborder="0"
          scrolling="no"
          :src="flowSrc"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/axios'
import EleForm from './components/eleForm.vue'

export default {
  components: { EleForm },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      updateKey: 0,
      flowSrc: '',
      formData: {},
      loading: false,
      isShow: false,
      tableData: [],
      page: { size: 10, current: 1 },
    }
  },
  methods: {
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getDataList()
    },
    handleEvent(type, row) {
      switch (type) {
        case 'query':
          this.page.current = 1
          this.getDataList()
          break
        case 'delete':
          this.$confirm(`确定删除${row.name}吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.removeModel(row)
          })
          break
        case 'editRelative':
          {
            const orderType = row.orderType ? row.orderType.split(',') : []
            this.$refs.formRef.initialData({ ...row, orderType })
          }
          break
        case 'edit':
          {
            const isProd = process.env.VUE_APP_ENV === 'prod'
            const url = `https://flow-portal${
              isProd ? '' : '-test'
            }.mokihi.com/static/modeler/#/editor/${
              row.id
            }?Tenant=cmpw&gateway_employee_no=3890&hideClose=true`

            this.isShow = true
            this.flowSrc = url
            this.updateKey++
          }
          break
        default:
      }
    },
    async removeModel({ id }) {
      const url = `cmp-workflow/flowModel/remove`

      await http.post(url, { id })
      this.$msg.success('操作成功')

      setTimeout(() => {
        this.getDataList()
      }, 1000)
    },
    async getDataList() {
      const url = `cmp-workflow/flowModel/pageBackEnd`
      const data = { ...this.page, ...this.params }

      try {
        this.loading = true
        const res = await http.post(url, data)
        const { records, total } = res.data ?? {}

        this.total = total
        this.tableData = records ?? []
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
