<template>
  <el-dialog :title="`${formData.id ? '编辑' : '创建'}流程模型`" :visible.sync="isShow" :width="'500px'">
    <el-form
      ref="ruleForm"
      label-width="100px"
      :rules="rules"
      :model="formData"
      style="padding: 20px"
    >
      <el-form-item label="流程模型名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="流程模型类型" prop="type">
        <el-select clearable v-model="formData.type" placeholder="请选择">
          <el-option
            :key="item.code"
            :value="item.code"
            :label="item.name"
            v-for="item in modelTypes"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联表单类型" prop="orderType" v-show="formData.id">
        <el-select clearable multiple collapse-tags v-model="formData.orderType" placeholder="请选择">
          <el-option
            :key="item.dictKey"
            :value="item.dictKey"
            :label="item.dictValue"
            v-for="item in formOrderTypes"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流程模型描述" prop="modelDesc">
        <el-input type="textarea" v-model="formData.modelDesc" :autosize="{ minRows: 3 }"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="isShow = false">取 消</el-button>
      <el-button type="primary" @click="handleEvent('submit')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import http from '@/utils/axios'

export default {
  data() {
    return {
      isShow: false,
      formData: {},
      modelTypes: [],
      formOrderTypes: [],
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
    }
  },
  methods: {
    handleEvent(type) {
      switch (type) {
        case 'submit':
          this.$refs['ruleForm'].validate((valid) => {
            if (!valid) return false
            this.submit()
          })
          break
        default:
      }
    },
    initialData(value) {
      this.isShow = true
      this.$refs['ruleForm']?.resetFields()
      this.formData = { ...this.formData, ...value }

      if (!this.modelTypes?.length) this.getModleTypes()
      if (!this.formOrderTypes?.length) this.getOrderTypes()
    },
    async getOrderTypes() {
      const url = `cmp-console/dict/admin/list`
      const res = await http.post(url, { dictKey: 'form_or_order_type' })
      this.formOrderTypes = res.data ?? []
    },
    async getModleTypes() {
      const url = `cmp-workflow/modelCategory/list`
      const res = await http.post(url)

      this.modelTypes = res.data ?? []
    },
    async submit() {
      const orderType = this.formData.orderType?.join(',') ?? ''
      const url = `cmp-workflow/flowModel/${
        this.formData.id ? 'update' : 'createBackEnd'
      }`

      await http.post(url, { ...this.formData, orderType })
      this.$msg.success('操作成功')

      setTimeout(() => {
        this.isShow = false
        this.$emit('update', true)
      }, 1000)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
