<template>
  <common-layout>
    <template #default>
      <el-table
        border
        stripe
        ref="table"
        :data="tableData"
        v-loading="loading"
        height="calc(100vh - 240px)"
      >
        <el-table-column type="index" label="#" align="center" width="80" />
        <el-table-column prop="code" label="分类编码" align="center" width="260" />
        <el-table-column prop="name" label="分类名称" align="center" width="260" />
        <el-table-column prop="createTime" label="创建时间" align="center" width="180" />
        <el-table-column prop="categoryDesc" label="备注" align="center">
          <template slot-scope="scope">{{scope.row.categoryDesc || '--'}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEvent('delete',scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        class="mt10"
        :page-size="page.size"
        :current-page="page.current"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </template>
  </common-layout>
</template>

<script>
import http from '@/utils/axios'

export default {
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: 0,
      tableData: [],
      loading: false,
      page: { size: 10, current: 1 },
    }
  },
  methods: {
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getDataList()
    },
    handleEvent(type, row) {
      switch (type) {
        case 'delete':
          this.$confirm(`确定删除${row.name}吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.removeItem(row)
          })
          break
        default:
      }
    },
    async removeItem({ code }) {
      const url = `cmp-workflow/modelCategory/remove`

      await http.post(url, { code })
      this.$msg.success('操作成功')

      setTimeout(() => {
        this.getDataList()
      }, 1000)
    },
    async getDataList() {
      const url = `cmp-workflow/modelCategory/pageBackEnd`
      const data = { ...this.page, ...this.params }

      try {
        this.loading = true
        const res = await http.post(url, data)
        const { records, total } = res.data ?? {}

        this.total = total
        this.tableData = records ?? []
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

